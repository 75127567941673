 /*******************************
          Site Overrides
 *******************************/

@import 'semantic.scss';

@font-face {
  font-family: 'roboto';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
}

body {
  margin: 0;
}

// $dark-blue: #004EA8; // mr blue
$dark-blue: #00589c; // mr styleguide blue
$light-blue: #0095DA;
$white: #ffffff;
$yellow: #ffd102;
$coral: #f16347;
$green: #6CBD45;
$red: #EC1F26;
$grey: #808080;
$light-grey: #f0f0f0;


$tiny-font-size: 12px;
$small-font-size: 12px;
$normal-font-size: 16px;
$large-font-size: 18px;

#myoRoot, .modal {
  .skip-to-main-content-link {
    display: none;
  }

  background-color: transparent;
  position: relative;
  z-index: 1;
  font-family: roboto, sans-serif;
  overflow: visible;


  .errorMessage {
    text-align: center;
    img.ui.image {
      margin: 0 auto !important;
    }

    a {
      text-decoration: underline;
    }

    .startShopping {
      // margin-left: 15px;
      font-size: $normal-font-size;
      background-color: $dark-blue;
      color: $white;
      text-decoration: none;

      &:hover {
        background-color: $grey;
      }
    }

    .codeBlock {
      display: block;
      clear: both;
      margin: 15px auto;
      width: fit-content;
      padding: 5px;
      background: #eee;
    }
  }
  
  .ui.myo_filters {
    .button {
      font-size: $normal-font-size;
      margin: 5px 5px 0 0;
      padding: 10px 15px;

      &.active {
        text-decoration: none;
        background-color: $dark-blue;
        color: $white;
        font-weight: normal;
      }
    }
  }

  .ui.label {
    font-size: $small-font-size;

    &.tiny {
      font-size: $tiny-font-size;
    }

    &.redeemedLabel {
      background-color: #E8E8E8 !important;
      color: rgba(0, 0, 0, 0.6) !important;
    }

    &.big {
      font-size: $normal-font-size;
    }

    &.green, &.saveonfoods_only, &.score_n_win, &.touchdown_to_win {
      background-color: $green;
      color: $white;
    }

    &.red, &.blf_tm_exclusive_offer, &.hot_offer, &.qf_employee_offer, &.darrells_deal {
      background-color: $red;
      color: $white;
    }

    &.coral {
      background-color: $coral;
      color: $white;
    }

    &.dark-blue, &.my_mega_deal, &.flash_offer {
      background-color: $dark-blue;
      color: $white;
    }

    &.light-blue, &.bonus_points {
      background-color: $light-blue;
      color: $white;
    }

    &.yellow, &.sponsored_offer {
      background-color: $yellow;
      color: $white;
    }
  }

  .offerBody {
    background-color: transparent;
  }

  .wrapper {
    padding: 30px 0;
  }

  .loader {
    margin-left: 15px;
  }

  .loading-bar {
    // padding: 60px 0;
    padding: 0 0;
    text-align: center;
  }

  .square.image{
    height: 100px;
  }

  .ui.raised.card, .ui.raised.cards>.card {
    position: relative;
    box-shadow: 0 0 0 1px #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 20px rgba(34,36,38,.15);
    display: flex;
    height: 100%;
    align-items: stretch;

    &>.content {
      min-height: 165px;
      display: flex;
      flex: 1;
      flex-direction: column;

      &>.extra {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
        font-size: $small-font-size;
        div {
          margin-top: 5px;
          color: #4183C4;
          text-decoration: none;
          font-size: $small-font-size;
        }
      }

      &>.innerContent {
        &>.header {
          font-family: roboto, sans-serif;
          font-size: $normal-font-size;
          font-weight: 900;
          color: $dark-blue;
          margin-bottom: 5px;
        }

        &>.description {
          font-size: $normal-font-size;
        }
      }
    }

    .image {
      padding: 15px 0 !important;
      background-color: #fff !important;
    }

    .image .img {
      min-height: 150px;
      background-size: contain;
      background-repeat: no-repeat;  
      background-position: center center;
      padding: 15px 0;
    }

    .image .ui.ribbon.label {
      position: absolute;
      left: -14px;
      top: 10px;

      span {
        display: block;
        margin-left: -14px;
      }
    }

    
    // &.reedemed {
    //   opacity: 0.6;
    // }

    // &.active .image, .content {
    //   opacity: 0.6;
    // }

    &.active:before {
      display: block;
      opacity: 1 !important;
    }

    .progress {
      margin-top: 10px;
      position: relative;
    }
  }

  .ui.container.notification {
    display: flex;
    align-items: flex-start;
    padding: 20px 0 24px;

    .banner_logo {
      width: 200px;
      max-width: 200px;

      &.urbanfare_logo, &.buylowfoods_logo {
        max-width: 100px;
      }

      &.pricesmartfoods_logo {
        margin-top: 10px;
      }
    }

    .myoBlock {
      width: 100%;
      display: flex;
      flex-flow: column wrap;
      margin: 10px 0 0 12px;
      
      svg {
        width: 200px;
        margin: 0 0 10px 0;
      }

      span {
        font-size: 1.5em;
      }
    }

    img, .myo_logo {
      transform: translateY(7px);
      margin: 0 15px 0 0;

      .arrow{fill:$yellow;}
      // .arrow{fill:#ffe600;}
      .arrow,.letters{stroke-width:0px;}
      .letters{fill:$dark-blue;}
      // .letters{fill:#065a9d;}
    }

    .segment {
      width: 100%;
      font-size: $normal-font-size;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      box-shadow: none;

      span {
        font-size: 1.5em;
        // font-style: italic;
      }

      .startShopping {
        // margin-left: 15px;
        font-size: $normal-font-size;
        background-color: $dark-blue;
        color: $white;

        &:hover {
          background-color: $grey;
        }
      }
    }
  }

  .footer {
    background-color: $light-grey;
    padding: 30px 0;
    .entity {
      padding: 10px 0;
    }
  }
}

@media screen and (max-width: 767px){
  .ui.dimmer {
    padding: 0 !important;
  }
  #myoRoot .ui.container.notification {
    display: block;

    .banner_logo {
      margin: 0 0 20px 15px;
    }

    img.myo_logo {
      margin: 15px 0 5px;
    }
    .segment {
      display: block;
      padding-left: 12px;
      margin-top: 0;
      span {
        display: block;
      }

      .startShopping {
        margin: 15px 0 0;
      }
    }
  }
}

@media screen and (max-width: 480px){

  .myoBlock {
    span {
      font-size: 1.3em;
    }
  }

  .card-list.ui.grid>[class*="four column"].doubling:not(.stackable).row.row>.column,
    .card-list.ui[class*="four column"].doubling:not(.stackable).grid>.column:not(.row),
    .card-list.ui[class*="four column"].doubling:not(.stackable).grid>.row>.column {
      width: 100% !important;
    }

    .ui.card {
      flex-direction: row;
      align-items: stretch;

      .image {
        width: 120px;

        .ui.label {
          font-size: 10px !important;
          
        }
      }

      .content {
        width: calc(100% - 200px);
        display: flex;
        justify-content: space-between;
      }
    }
}

.ui.modal .image.content, .ui.modal .content {
  max-width: 90%;
}

.ui.modal  {
  font-family: roboto, sans-serif;

  .content:nth-of-type(2) {
    padding-top: 0px;
  }

  .image.content .image {
    min-width: 150px;
  }
  .description {
    h2 {
      font-family: roboto, sans-serif;
      font-size: $normal-font-size;
      font-weight: 900;
      color: $dark-blue;
    }

    p {
      font-size: $normal-font-size;
      margin: 0 0 15px;
    }
  }

  .eligibleProductList {
    // display: none;
  }
}